import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isCartOpen: false,
    cart: [],
    items: [],
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },
        
        // Modificar addToCart para incluir la talla
        addToCart: (state, action) => {
            const { id, talla } = action.payload.item;
            // Crear un identificador único basado en el id del producto y la talla seleccionada
            const uniqueId = `${id}-${talla}`;
            
            // Verificar si ya existe un producto con el mismo id y talla
            const itemInCart = state.cart.find((item) => item.uniqueId === uniqueId);

            if (itemInCart) {
                // Si ya existe, incrementar el count
                itemInCart.count += action.payload.item.count;
            } else {
                // Si no existe, agregar el producto al carrito con el identificador único
                state.cart = [...state.cart, { ...action.payload.item, uniqueId }];
            }
        },
        
        removeFromCart: (state, action) => {
            const { uniqueId } = action.payload; // Usamos el uniqueId para eliminar un producto específico
            state.cart = state.cart.filter((item) => item.uniqueId !== uniqueId);
        },

        increaseCount: (state, action) => {
            state.cart = state.cart.map((item) => {
                if (item.id === action.payload.id){
                    item.count++;
                }
                return item;
            });
        },

        decreaseCount: (state, action) => {
            state.cart = state.cart.map((item) => {
                if (item.id === action.payload.id && item.count > 1){
                    item.count--;
                }
                return item;
            });
        },

        setIsCartOpen: (state) => {
            state.isCartOpen = !state.isCartOpen;
        },

        // Definir setCurrentCategory para actualizar la categoría actual
        setCurrentCategory: (state, action) => {
            state.currentCategory = action.payload;
        },
    }
})

export const {
    setItems,
    addToCart,
    removeFromCart,
    increaseCount,
    decreaseCount,
    setIsCartOpen,
    setCurrentCategory  // Exportar setCurrentCategory
} = cartSlice.actions;

export default cartSlice.reducer;

