import { Box, Checkbox, FormControlLabel, Typography, useMediaQuery } from "@mui/material";
import AddressForm from "./AddressForm";


const Shipping = (
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
) => {
    // Verificar que shippingAddress y billingAddress estén definidos
    const billingAddress = values.billingAddress || {};
    const shippingAddress = values.shippingAddress || {};
    const isSameAddress = shippingAddress.isSameAddress ?? true;
    // Detectar si es móvil
    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <Box m="30px auto" sx={{ padding: 3, backgroundColor: "#ffffff", boxShadow: 3 }}>
            {/* Billing Form */}
            <Box>
                <Typography sx={{ mb: "15px" }} fontSize="18px">
                    Información de Facturación
                </Typography>
                <AddressForm
                    type="billingAddress"
                    values={billingAddress}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                />
            </Box>
            <Box mb="20px">
                <FormControlLabel
                    label="Mismos datos para dirección de envío"
                    control={
                        <Checkbox
                            defaultChecked
                            value={isSameAddress}
                            onChange={() =>
                                setFieldValue(
                                    "shippingAddress.isSameAddress",
                                    !isSameAddress
                                )
                            }
                        />
                    }
                />
            </Box>

            {/* SHIPPING FORM */}
            {!isSameAddress && (
                <Box>
                    <Typography sx={{ mb: "15px" }} fontSize="18px">
                        Información de envío
                    </Typography>
                    <AddressForm
                        type="shippingAddress"
                        values={shippingAddress}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                    />
                </Box>
            )}
        </Box>
    );
};

export default Shipping;
