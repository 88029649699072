import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Tab, Tabs, useMediaQuery, MenuItem, Select, Button, Divider } from "@mui/material";
import Item from "../../components/Item";
import { setItems } from "../../state";
import { useLocation } from 'react-router-dom';

const ShoppingList = ({ conf }) => {
    const dispatch = useDispatch();
    const currentCategory = useSelector((state) => state.cart.currentCategory);
    const [value, setValue] = useState(conf.tabs[0]?.value || "all");
    const items = useSelector((state) => state.cart.items);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get('search') || '';

    async function getItems() {
        try {
            const response = await fetch("https://api.ternurareal.mx/api/items?populate=image,marca", { method: "GET" });
            const itemsJson = await response.json();
            dispatch(setItems(itemsJson.data));
        } catch (error) {
            console.error("Error al obtener los ítems:", error);
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    useEffect(() => {
        setValue(conf.tabs[0]?.value || "all");
    }, [currentCategory, conf.tabs]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(event.target.value);
        setCurrentPage(1);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    var filteredItems;
    if (conf.currentCategory != "Search") {
        filteredItems = value === "all"
            ? items.filter(item =>
                conf.tabs.slice(1).some(tab => item.attributes.category === tab.value)
            )
            : items.filter(item => item.attributes.category === value);
    }
    else {
        filteredItems = items;
    }

    filteredItems = filteredItems.filter(item =>
        item.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedItems = filteredItems.sort((a, b) => {
        return a.attributes.name.localeCompare(b.attributes.name);
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = sortedItems.slice(startIndex, endIndex);
    const totalPages = Math.ceil(sortedItems.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        scrollToTop();
    };

    return (
        <Box width={isNonMobile ? "80%" : "100%"} margin={isNonMobile ? "120px auto" : "80px auto"}>
            {/* Ajuste del contenedor de Tabs para móviles */}
            <Box
                sx={{
                    overflowX: 'auto',  // Permite desplazamiento horizontal en móviles
                    whiteSpace: 'nowrap',  // Mantiene las pestañas en una sola línea
                    width: '100%',
                    '&::-webkit-scrollbar': {
                        display: 'none',  // Ocultar la barra de desplazamiento en móviles
                    },
                    msOverflowStyle: 'none',  // Ocultar la barra de desplazamiento en IE y Edge
                    scrollbarWidth: 'none',  // Ocultar la barra de desplazamiento en Firefox
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#e10098",
                            height: "3px",
                            fontSize:"18px",
                        },
                    }}
                    sx={{
                        "& .MuiTab-root": {
                            textTransform: "none",
                            fontWeight: "bold",
                            color: "#333",
                            minWidth: "auto",
                            padding: "10px 20px",
                            whiteSpace: 'nowrap',  // Asegura que no se divida el texto en varias líneas
                            "&:hover": {
                                color: "#e10098",
                            },
                            fontSize:"12px",
                        },
                        "& .Mui-selected": {
                            color: "#e10098",
                        },
                    }}
                >
                    {conf.tabs.map((tab) => (
                        <Tab label={tab.label} value={tab.value} key={tab.value} />
                    ))}
                </Tabs>
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px" pl="10px" pr="10px">
                <Box display="flex" alignItems="center">
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '12px', color: '#333' }}>
                        {`Pág. ${currentPage} de ${totalPages}`}
                    </Typography>

                    <Box display="flex" ml={2}>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <Button
                                key={index + 1}
                                onClick={() => handlePageChange(index + 1)}
                                sx={{
                                    mx: "5px",
                                    backgroundColor: currentPage === index + 1 ? "#e10098" : "#ddd",
                                    color: currentPage === index + 1 ? "#fff" : "#000",
                                    "&:hover": {
                                        backgroundColor: "#e10098",
                                        color: "#fff",
                                    },
                                }}
                            >
                                {index + 1}
                            </Button>
                        ))}
                    </Box>
                </Box>

                <Box>
                    <Select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        size="small"
                        disableScrollLock={true}
                        sx={{
                            minWidth: "75px",
                            backgroundColor: "#fff",
                            borderRadius: "4px",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                            "& .MuiOutlinedInput-input": {
                                padding: "8px 14px",
                            },
                        }}
                        MenuProps={{
                            PaperProps: { sx: { maxHeight: 300 } },
                            disableScrollLock: true
                        }}
                    >
                        <MenuItem disabled>Mostrar:</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </Box>
            </Box>

            <Box
                margin="0 auto"
                display="grid"
                gridTemplateColumns={isNonMobile ? "repeat(auto-fill, 250px)" : "repeat(auto-fill, 45%)"}
                justifyContent="space-around"
                rowGap="20px"
                columnGap="1.33%"
            >
                {paginatedItems.length > 0 ? (
                    paginatedItems.map((item) => (
                        <Item item={item} key={`${item.attributes.name}-${item.id}`} />
                    ))
                ) : (
                    <Typography>No se encontraron productos.</Typography>
                )}
            </Box>

            {totalPages > 1 && (
                <Box display="flex" justifyContent="center" mt="20px">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            sx={{
                                mx: "5px",
                                backgroundColor: currentPage === index + 1 ? "#e10098" : "#ddd",
                                color: currentPage === index + 1 ? "#fff" : "#000",
                                "&:hover": {
                                    backgroundColor: "#e10098",
                                    color: "#fff",
                                },
                            }}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default ShoppingList;
