import { Box, Button, Divider, IconButton, Typography, Paper, useMediaQuery, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import styled from "@emotion/styled";
import { shades } from "../../theme";
import {
    decreaseCount,
    increaseCount,
    removeFromCart,
} from "../../state";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartMenu = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart.cart);

    // Detectar si es móvil
    const isMobile = useMediaQuery("(max-width:600px)");

    // Calcula el precio total, manejando los productos sin precio
    const totalPrice = cart.reduce((total, item) => {
        const itemPrice = item.attributes?.price || 0;
        return total + (item.count ? item.count : 1) * itemPrice;
    }, 0);

    const handleNavigateToCheckout = () => {
        // Eventos de Google Analytics
        ReactGA.event({
            category: 'Compra',
            action: 'CompletarCompra',
            label: ''
        });
        navigate("/checkout");
    };

    const IVA = 0.00;
    const totalConIVA = totalPrice + IVA;

    return (
        <Box
            sx={{
                backgroundColor: "#f5f5f5",
                minHeight: "100vh",
                padding: isMobile ? "80px 0px 40px 0px" : "130px 0px 40px 0px",
            }}
        >
            <Box
                display="flex"
                justifyContent="left"
                width={isMobile ? "95%" : "80%"}
                margin="0 auto"
                columnGap="20px"
                alignItems="flex-start"
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold',
                        color: '#000',
                        fontSize: '1.2rem',
                        textAlign: 'left',
                        mb: 4,
                    }}
                >
                    Carrito:
                </Typography>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                width={isMobile ? "95%" : "80%"}
                margin="0 auto"
                columnGap="20px"
                alignItems="flex-start"
                flexDirection={isMobile ? "column" : "row"}
            >
                <Box flex="2" width={isMobile ? "100%" : "auto"}>
                    {!isMobile && (
                        <Paper sx={{ padding: 2, mb: 2 }}>
                            <FlexBox sx={{mb: 0 }}>
                                <Box flex="1 1 30%">
                                    <Typography sx={{ fontSize: '12px' }}>Producto</Typography>
                                </Box>
                                <Box flex="1 1 20%" textAlign="center">
                                    <Typography sx={{fontSize: '12px' }}>Precio unitario</Typography>
                                </Box>
                                <Box flex="1 1 20%" textAlign="center">
                                    <Typography sx={{fontSize: '12px' }}>Cantidad</Typography>
                                </Box>
                                <Box flex="1 1 20%" textAlign="center">
                                    <Typography sx={{fontSize: '12px' }}>Subtotal</Typography>
                                </Box>
                                <Box flex="1 1 10%" textAlign="center">
                                </Box>
                            </FlexBox>
                        </Paper>
                    )}

                    <Paper sx={{ padding: 2 }}>
                        {cart.length > 0 ? (
                            cart.map((item) => (
                                <Box key={`${item.uniqueId}-${item.talla}`} mb={2}> {/* Usamos uniqueId aquí */}
                                    <FlexBox
                                        p="10px 0"
                                        alignItems="flex-start"
                                        flexDirection={isMobile ? "column" : "row"}
                                    >
                                        <Box flex="1 1 40%" display="flex" alignItems="flex-start">
                                            <Box
                                                component="a"
                                                onClick={() => navigate(`/item/${item.id}`)}  // Navegar al detalle del producto
                                                sx={{ cursor: 'pointer' }} // Añadir cursor de pointer
                                            >
                                                <img
                                                    alt={item.name}
                                                    width="123px"
                                                    height="164px"
                                                    src={`https://api.ternurareal.mx${item?.attributes?.image?.data[0]?.attributes?.formats?.thumbnail?.url || ""}`}
                                                />
                                            </Box>
                                            <FlexBox flexDirection="column"
                                                sx={{
                                                    color: '#000',
                                                    textAlign: 'left',
                                                    pt: "5px"
                                                }}>
                                                <Typography
                                                    ml={2}
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        textTransform: 'uppercase',
                                                        width: "100%",
                                                        fontSize: ".9em"
                                                    }}
                                                >
                                                    {item.attributes?.name || "Producto desconocido"}
                                                </Typography>
                                                <Typography
                                                    ml={2}
                                                    sx={{
                                                        fontWeight: 'normal',
                                                        width: "100%",
                                                        fontSize: ".9em"
                                                    }}
                                                >
                                                    {item.talla ? "Talla: " + item.talla : ""}
                                                </Typography>
                                            </FlexBox>
                                        </Box>

                                        <FlexBox
                                            flex="1 1 100%"
                                            flexDirection={isMobile ? "row" : "row"}
                                            justifyContent="space-between"
                                            mt={isMobile ? 2 : 0}
                                            mb={isMobile ? 2 : 0}
                                            sx={{
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <Box flex="1" textAlign="center">
                                                <Typography sx={{ color: "red" }}>
                                                    {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.attributes?.price || 0)}
                                                </Typography>
                                            </Box>

                                            <Box
                                                flex="1"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                textAlign="center"
                                            >
                                                <IconButton
                                                    sx={{
                                                        backgroundColor: "#d6d6d6", mr: "10px", color: "#000",
                                                        '&:hover': {
                                                            backgroundColor: "#e10098",
                                                            color: "#fff"
                                                        },
                                                    }}
                                                    onClick={() => dispatch(decreaseCount({ id: item.id }))}>
                                                    <RemoveIcon />
                                                </IconButton>
                                                <Typography>{item.count ? item.count : 1}</Typography>
                                                <IconButton
                                                    sx={{
                                                        backgroundColor: "#d6d6d6", ml: "10px", color: "#000",
                                                        '&:hover': {
                                                            backgroundColor: "#e10098",
                                                            color: "#fff"
                                                        },
                                                    }}
                                                    onClick={() => dispatch(increaseCount({ id: item.id }))}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Box>

                                            <Box flex="1" textAlign="center">
                                                <Typography fontWeight="bold" sx={{ color: "red" }}>
                                                    {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format((item.count ? item.count : 1) * (item.attributes?.price || 0))}
                                                </Typography>
                                            </Box>
                                        </FlexBox>

                                        <Tooltip
                                            title="Quitar del carrito"
                                            arrow
                                            placement="top"
                                            sx={{
                                                "& .MuiTooltip-tooltip": {
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    fontSize: "0.875rem",
                                                },
                                                "& .MuiTooltip-arrow": {
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            <Button
                                                onClick={() => dispatch(removeFromCart({ uniqueId: item.uniqueId }))} // Usar uniqueId para remover el elemento
                                                variant="contained"
                                                startIcon={<DeleteIcon />}
                                                sx={{
                                                    backgroundColor: "#f0f0f0",
                                                    color: "black",
                                                    '&:hover': {
                                                        backgroundColor: "#d6d6d6",
                                                    },
                                                    mt: isMobile ? 2 : 0,
                                                    alignSelf: isMobile ? "flex-start" : "center",
                                                }}
                                            >
                                                Quitar
                                            </Button>
                                        </Tooltip>
                                    </FlexBox>
                                    <Divider />
                                </Box>
                            ))
                        ) : (
                            <Typography variant="h6" textAlign="center" mt={4}>
                                Tu carrito está vacío.
                            </Typography>
                        )}
                    </Paper>
                </Box>

                <Box flex="1" mt={isMobile ? 4 : 0} width={isMobile ? "100%" : "auto"}>
                    <Paper sx={{ padding: 3, backgroundColor: "#ffffff", boxShadow: 3 }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                letterSpacing: '0.5px',
                                mb: 3,
                            }}
                        >
                            Resumen del pedido
                        </Typography>

                        {cart.length > 0 && (
                            <Box>
                                <FlexBox mb="5px">
                                    <Typography fontWeight="normal">Subtotal:</Typography>
                                    <Typography fontWeight="normal">
                                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalPrice)}
                                    </Typography>
                                </FlexBox>

                                <FlexBox mb="5px">
                                    <Typography variant="h5" fontWeight="normal">Iva incluido:</Typography>
                                    <Typography variant="h5" fontWeight="normal">
                                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(IVA)}
                                    </Typography>
                                </FlexBox>
                                <FlexBox mb="5px">
                                    <Typography variant="h5" fontWeight="normal">Gastos de envío:</Typography>
                                    <Typography variant="h5" fontWeight="normal">
                                        No incluido
                                        {/*new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(IVA)*/}
                                    </Typography>
                                </FlexBox>
                                <Divider />
                                <FlexBox m="15px 0px 20px 0px">
                                    <Typography variant="h5" fontWeight="bold">Total (IVA incluido):</Typography>
                                    <Typography variant="h5" fontWeight="bold" sx={{ color: "black" }}>
                                        {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalConIVA)}
                                    </Typography>
                                </FlexBox>

                                <Button
                                    sx={{
                                        backgroundColor: "#e10098",
                                color: "white",
                                borderRadius: 0,
                                        minWidth: "100%",
                                        padding: "10px 20px",
                                        mb: "10px",
                                        '&:hover': {
                                            backgroundColor: "#b7007b",
                                    color: "white",
                                    fontWeight: "bold",
                                        }
                                    }}
                                    onClick={() => { handleNavigateToCheckout(); }}
                                >
                                    Continuar la compra
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: "#ffffff", // Fondo blanco
                                        color: "#e10098", // Letra rosa
                                        border: "2px solid #e10098", // Borde rosa
                                        borderRadius: 0,
                                        minWidth: "100%",
                                        padding: "10px 20px",
                                        mb: "0px",
                                        '&:hover': {
                                            backgroundColor: "#f7f7f7", // Fondo gris claro al hacer hover
                                            color: "#e10098", // Mantener la letra rosa
                                            fontWeight: "bold",
                                        }
                                    }}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    Seguir comprando
                                </Button>
                            </Box>
                        )}
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
};

export default CartMenu;
