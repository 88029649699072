import { useEffect } from "react";
import { initGA, logPageView } from "./utils/analytics";  // Ajusta el path según tu estructura

import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Checkout from "./scenes/checkout/Checkout";
import Home from "./scenes/home/Home";
import ItemDetails from "./scenes/itemDetails/ItemDetails";
import Confirmation from "./scenes/checkout/Confirmation";
import Navbar from "./scenes/global/Navbar";
import Cart from "./scenes/account/CartMenu";
import Footer from "./scenes/global/Footer";
import Rules from "./scenes/global/Rules";
import Test from "./scenes/global/Test";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname])

  useEffect(() => {
      initGA(); // Inicializar GA cuando la aplicación cargue
      logPageView(); // Registrar la primera visita a una página
  }, []);

  useEffect(() => {
      logPageView(); // Registrar cada cambio de página
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar></Navbar>
        <ScrollToTop></ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="item/:itemId" element={<ItemDetails/>}></Route>
            <Route path="checkout" element={<Checkout/>}></Route>
            <Route path="checkout/success" element={<Confirmation/>}></Route>
            <Route path="cart" element={<Cart/>}></Route>
            <Route path="rules" element={<Rules/>}></Route>
            <Route path="test" element={<Test/>}></Route>
          </Routes>
          <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
