import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Box, Typography, Button, Tabs, Tab, Snackbar, Alert, Divider, useMediaQuery, Dialog, DialogContent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { shades } from "../../theme";
import { addToCart } from "../../state";
import { useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

const ItemDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { itemId } = useParams();
    const [value, setValue] = useState("reviews");
    const [count, setCount] = useState(1);
    const [item, setItem] = useState(null);
    const [items, setItems] = useState([]);
    const [tallas, setTallas] = useState([]);
    const [selectedTalla, setSelectedTalla] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showError, setShowError] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const [isExpanded, setIsExpanded] = useState(false); // Estado para la vista expandida
    const [expandedImageIndex, setExpandedImageIndex] = useState(0); // Índice de la imagen expandida

    const isMobile = useMediaQuery("(max-width:600px)");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function getItem() {
        const item = await fetch(
            `https://api.ternurareal.mx/api/items/${itemId}?populate=image,tipo`,
            { method: "GET" }
        );
        const itemJson = await item.json();
        setItem(itemJson.data);
        setSelectedImageUrl(itemJson.data?.attributes?.image?.data[0]?.attributes?.url || "");
    }

    async function getItems() {
        const items = await fetch(
            `https://api.ternurareal.mx/api/items?populate=image,tipo`,
            { method: "GET" }
        );
        const itemsJson = await items.json();
        setItems(itemsJson.data);
    }

    async function getTallasByTipo(tipoProducto) {
        const tipos = await fetch(
            `https://api.ternurareal.mx/api/tipos?populate=tallas`,
            { method: "GET" }
        );
        const tiposJson = await tipos.json();

        const tipoEncontrado = tiposJson.data.find(tipo => tipo.attributes.name === tipoProducto);

        if (tipoEncontrado) {
            let tallasDisponibles = tipoEncontrado.attributes.tallas?.data || [];
            tallasDisponibles = tallasDisponibles.sort((a, b) => {
                const tallaA = parseFloat(a.attributes.talla);
                const tallaB = parseFloat(b.attributes.talla);
                return tallaA - tallaB;
            });
            setTallas(tallasDisponibles);
        } else {
            setTallas([]);
        }
    }

    useEffect(() => {
        async function fetchData() {
            await getItem();
            await getItems();
        }

        fetchData();
    }, [itemId]);

    useEffect(() => {
        if (item && item.attributes?.tipo?.data?.attributes?.name) {
            const tipoProducto = item.attributes.tipo.data.attributes.name;
            getTallasByTipo(tipoProducto);
        }
    }, [item]);

    // Función para agregar el producto al carrito junto con la talla seleccionada
    const handleAddToCart = (name) => {
        if (tallas.length > 0 && !selectedTalla) {
            setShowError(true);
            return;
        }

        ReactGA.event({
            category: 'Compra',
            action: 'AgregarCarrito',
            label: itemId + ' -> ' + name
        });

        // Agrega el producto al carrito junto con la talla seleccionada
        dispatch(addToCart({ item: { ...item, count, talla: selectedTalla } }));
        setOpenSnackbar(true);
        setShowError(false);
    };

    const handleNavigateToCart = () => {
        // Eventos de Google Analytics
        ReactGA.event({
            category: 'Compra',
            action: 'IrAlCarrito',
            label: ''
        });
        navigate("/cart");
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSelectTalla = (talla) => {
        setSelectedTalla(talla);
        setShowError(false);
    };

    const openExpandedView = (index) => {
        setExpandedImageIndex(index);
        setIsExpanded(true);
    };

    const closeExpandedView = () => {
        setIsExpanded(false);
    };

    const handleNextImage = () => {
        setExpandedImageIndex((prevIndex) => (prevIndex + 1) % item?.attributes?.image?.data.length);
    };

    const handlePrevImage = () => {
        setExpandedImageIndex((prevIndex) => (prevIndex - 1 + item?.attributes?.image?.data.length) % item?.attributes?.image?.data.length);
    };

    return (
        <Box width="80%" m="120px auto">
            {/* Cambiar flexDirection basado en si es móvil o no */}
            <Box display="flex" flexDirection={isMobile ? "column" : "row"} columnGap="20px">
                {/* Main Image */}
                <Box flex="1 1 30%" mb="0px" display="flex" alignItems="flex-start" alignSelf="flex-start">
                    <img
                        alt={item?.name}
                        width="100%"
                        height="auto"
                        src={`https://api.ternurareal.mx${selectedImageUrl}`}
                        style={{ objectFit: "contain", alignSelf: "flex-start", maxWidth: "500px", cursor: "pointer" }}
                        onClick={() => openExpandedView(0)} // Abrir la vista expandida al hacer clic
                    />
                    
                </Box>

                {/* Thumbnail Images List */}
                <Box display={isMobile ? "block" : "flex"} flexDirection={isMobile ? "column" : "column"} alignItems="flex-start" mt={isMobile ? "10px" : "0"}>
                    {item?.attributes?.image?.data?.map((img, index) => (
                        <img
                            key={index}
                            src={`https://api.ternurareal.mx${img?.attributes?.formats?.thumbnail?.url}`}
                            alt={`Thumbnail ${index}`}
                            style={{
                                width: "60px",
                                height: "60px",
                                margin: isMobile ? "0 10px" : "10px 0",
                                cursor: "pointer",
                                border: selectedImageUrl === img?.attributes?.url ? "2px solid #e10098" : "none",
                            }}
                            onClick={() => setSelectedImageUrl(img?.attributes?.url)}
                        />
                    ))}
                </Box>

                {/* ACTIONS */}
                <Box flex="1 1 60%" mb="0px" mt={isMobile ? "20px" : "0"}>
                    {/* Descripción y acciones */}
                    <Box m="20px 0 25px 0">
                        <Typography variant="h3">{item?.attributes?.name}</Typography>
                        <Typography fontWeight="bold" sx={{ color: "red", fontSize: "1.1em", mt: "5px" }}>
                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item?.attributes?.price)}
                        </Typography>
                        <Typography sx={{ mt: "20px" }}>
                            {typeof item?.attributes?.shortDescription[0]?.children[0]?.text === 'string'
                                ? item?.attributes?.shortDescription[0]?.children[0]?.text
                                : "Descripción no disponible"}
                        </Typography>
                        <Typography sx={{ mt: "20px" }} component="div">
                            {item?.attributes?.longDescription?.map((desc, index) => (
                                <div key={index}>
                                    {desc.children.map((child, childIndex) => (
                                        <Typography key={childIndex} paragraph>
                                            {child.text}
                                        </Typography>
                                    ))}
                                </div>
                            ))}
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 3 }} />

                    {/* SELECCIÓN DE TALLAS */}
                    <Box>
                        <Typography variant="h6" sx={{ mb: 2, fontSize: "1em", fontWeight: "bold" }}>Selecciona tu talla:</Typography>

                        {tallas.length > 0 ? (
                            <Box display="flex" flexWrap="wrap" gap={2}>
                                {tallas.map((talla, index) => (
                                    <Button
                                        key={index}
                                        variant={selectedTalla === talla.attributes.talla ? "contained" : "outlined"}
                                        onClick={() => handleSelectTalla(talla.attributes.talla)}
                                        sx={{
                                            padding: "10px 20px",
                                            fontSize: "14px",
                                            backgroundColor: selectedTalla === talla.attributes.talla ? "#222" : "#fff",
                                            color: selectedTalla === talla.attributes.talla ? "#fff" : "#000",
                                            borderRadius: "4px",
                                            '&:hover': {
                                                backgroundColor: "#222",
                                                color: "#fff",
                                            }
                                        }}
                                    >
                                        {talla.attributes.talla}
                                    </Button>
                                ))}
                            </Box>
                        ) : (
                            <Typography sx={{ mt: 2 }}>Talla: Unitalla</Typography>
                        )}

                        {showError && (
                            <Typography color="error" sx={{ mt: 2, fontWeight: "bold" }}>Debes seleccionar una talla.</Typography>
                        )}
                    </Box>

                    <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center" minHeight="50px" mt="20px">
                        <Box
                            display="flex"
                            alignItems="center"
                            mr={isMobile ? "0" : "20px"}
                            p="2px 5px"
                            mb={isMobile ? "10px" : "0"}
                            width={isMobile ? "100%" : "auto"}
                        >
                            <IconButton
                                sx={{
                                    backgroundColor: "#d6d6d6", mr: "10px", color: "#000",
                                    '&:hover': {
                                        backgroundColor: "#e10098",
                                        color: "#fff"
                                    },
                                }}
                                onClick={() => setCount(Math.max(count - 1, 1))}>
                                <RemoveIcon />
                            </IconButton>
                            <Typography sx={{ p: "0 5px" }}>{count}</Typography>
                            <IconButton
                                sx={{
                                    backgroundColor: "#d6d6d6", ml: "10px", color: "#000",
                                    '&:hover': {
                                        backgroundColor: "#e10098",
                                        color: "#fff"
                                    },
                                }}
                                onClick={() => setCount(count + 1)}>
                                <AddIcon />
                            </IconButton>
                        </Box>

                        <Button
                            sx={{
                                backgroundColor: "#222222",
                                color: "white",
                                borderRadius: 0,
                                minWidth: isMobile ? "100%" : "150px",
                                padding: "10px 40px",
                                mb: isMobile ? "10px" : "0",
                                '&:hover': {
                                    color: "#000",
                                    cursor: 'pointer',
                                    fontWeight: "bold",
                                    backgroundColor: "%ddd",
                                }
                            }}
                            onClick={() => handleAddToCart(item?.attributes?.name)}
                        >
                            Agregar al carrito
                        </Button>
                        <Button
                            sx={{
                                ml: isMobile ? "0" : "10px",
                                backgroundColor: "#e10098",
                                color: "white",
                                borderRadius: 0,
                                minWidth: isMobile ? "100%" : "150px",
                                padding: "10px 40px",
                                '&:hover': {
                                    backgroundColor: "#b7007b",
                                    color: "white",
                                    fontWeight: "bold",
                                }
                            }}
                            onClick={() => handleNavigateToCart()}
                        >
                            Ir al carrito
                        </Button>
                        <Button
                            sx={{
                                ml: isMobile ? "0px" : "10px",
                                mt: isMobile ? "10px" : "0px",
                                backgroundColor: "#ffffff", // Fondo blanco
                                color: "#e10098", // Letra rosa
                                border: "2px solid #e10098", // Borde rosa
                                borderRadius: 0,
                                minWidth: isMobile ? "100%" : "150px",
                                padding: "10px 40px",
                                '&:hover': {
                                    backgroundColor: "#f7f7f7", // Fondo gris claro al hacer hover
                                    color: "#e10098", // Mantener la letra rosa
                                    fontWeight: "bold",
                                }
                            }}
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Seguir comprando
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box m="20px 0">
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="REVIEWS" value="reviews" />
                </Tabs>
            </Box>
            <Box display="flex" flexWrap="wrap" gap="15px">
                {value === "reviews" && <div>Reseñas</div>}
            </Box>

            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={7000}
                onClose={handleSnackbarClose}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        marginTop: '80px',
                        right: '20px',
                    },
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    iconMapping={{
                        success: <CheckCircleIcon sx={{ color: 'white' }} />
                    }}
                    sx={{ width: '100%', backgroundColor: '#b7007b', color: 'white' }}
                >
                    Haz agregado un producto a tu carrito.
                </Alert>
            </Snackbar>

            {/* Vista expandida de la imagen */}
            <Dialog open={isExpanded} onClose={closeExpandedView} maxWidth="lg" fullWidth>
                <DialogContent sx={{ backgroundColor: "rgba(0,0,0,0.85)", position: "relative", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <IconButton onClick={closeExpandedView} sx={{ position: "absolute", top: "10px", right: "10px", color: "white" }}>
                        <CloseIcon />
                    </IconButton>

                    <IconButton onClick={handlePrevImage} sx={{
                        position: "absolute", left: "10px", color: "white",
                        backgroundColor: "#000", // Color de fondo
                        '&:hover': {
                            backgroundColor: "#b7007b" // Cambiar a un color más oscuro al hacer hover
                        },
                        '&:active': {
                            backgroundColor: "#e10098" // Mantener el color al seleccionarlo
                        }
                    }}>
                        <ArrowBackIosIcon />
                    </IconButton>

                    <img
                        src={`https://api.ternurareal.mx${item?.attributes?.image?.data[expandedImageIndex]?.attributes?.url}`}
                        alt="Expanded"
                        style={{ maxHeight: "80vh", maxWidth: "80vw", objectFit: "contain" }}
                    />

                    <IconButton onClick={handleNextImage} sx={{
                        position: "absolute", right: "10px", color: "white",
                        backgroundColor: "#000", // Color de fondo
                        '&:hover': {
                            backgroundColor: "#b7007b" // Cambiar a un color más oscuro al hacer hover
                        },
                        '&:active': {
                            backgroundColor: "#e10098" // Mantener el color al seleccionarlo
                        }
                    }}>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </DialogContent>
            </Dialog>
            
        </Box>
    );
};

export default ItemDetails;
