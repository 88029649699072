import { useSelector } from "react-redux";
import { Box, Button, Stepper, Step, StepLabel, Typography,useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import Shipping from "./Shipping";
import { shades } from "../../theme";
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Icono de WhatsApp de Material UI
//import Model3DViewer from '../global/Model3DViewer';

const initialValues = {
    billingAddress: {
        firstName: "",
        lastName: "",
        country: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
    },
    shippingAddress: {
        isSameAddress: true,
        firstName: "",
        lastName: "",
        country: "",
        street1: "",
        street2: "",
        city: "",
        state: "",
        zipCode: "",
    },
    email: "",
    phoneNumber: "",
};

const checkoutSchema = [
    yup.object().shape({
        billingAddress: yup.object().shape({
            firstName: yup.string().required("Este campo es obligatorio"),
            lastName: yup.string().required("Este campo es obligatorio"),
            country: yup.string().required("Este campo es obligatorio"),
            street1: yup.string().required("Este campo es obligatorio"),
            street2: yup.string(),
            city: yup.string().required("Este campo es obligatorio"),
            state: yup.string().required("Este campo es obligatorio"),
            zipCode: yup.string().required("Este campo es obligatorio"),
        }),
        shippingAddress: yup.object().shape({
            isSameAddress: yup.boolean(),
            firstName: yup.string().when("isSameAddress", {
                is: false,
                then: yup.string().required("Este campo es obligatorio"),
            }),
            lastName: yup.string().when("isSameAddress", {
                is: false,
                then: yup.string().required("Este campo es obligatorio"),
            }),
            country: yup.string().when("isSameAddress", {
                is: false,
                then: yup.string().required("Este campo es obligatorio"),
            }),
            street1: yup.string().when("isSameAddress", {
                is: false,
                then: yup.string().required("Este campo es obligatorio"),
            }),
            street2: yup.string(),
            city: yup.string().when("isSameAddress", {
                is: false,
                then: yup.string().required("Este campo es obligatorio"),
            }),
            state: yup.string().when("isSameAddress", {
                is: false,
                then: yup.string().required("Este campo es obligatorio"),
            }),
            zipCode: yup.string().when("isSameAddress", {
                is: false,
                then: yup.string().required("Este campo es obligatorio"),
            }),
        }),
    }),
    yup.object().shape({
        email: yup.string().required("Este campo es obligatorio"),
        phoneNumber: yup.string().required("Este campo es obligatorio"),
    }),
];

const Checkout = () => {
    const [activeStep, setActiveStep] = useState(0);
    const cart = useSelector((state) => state.cart.cart);
    const isFirstStep = activeStep === 0;
    const isSecondStep = activeStep === 1;
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleFormSubmit = async (values, actions) => {
        setActiveStep(activeStep + 1);
    };

    return (
        <Box width="80%" m="130px auto">
            {/* <Stepper activeStep={activeStep} sx={{ m: "20px 0" }}>
                <Step>
                    <StepLabel>Facturación</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Pago</StepLabel>
                </Step>
            </Stepper>
            <Box>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema[activeStep]}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                    }) => {
                        console.log("Errors:", errors); // Depuración de errores
                        console.log("Touched:", touched); // Depuración de campos tocados

                        return (
                            <form onSubmit={handleSubmit}>
                                {isFirstStep && (
                                    <Shipping
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        handleSubmit={handleSubmit}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </form>
                        );
                    }}
                </Formik>
            </Box> */}

<Box
    sx={{
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#f7f7f7",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
    }}
>
    <Typography 
        variant="h4" 
        sx={{ 
            color: "#e10098", 
            fontWeight: "bold", 
            mb: 2 
        }}
    >
        ¡Pagos con tarjeta disponibles muy pronto!
    </Typography>
    <Typography 
        variant="h5" 
        sx={{ 
            color: "#333", 
            fontSize: "1.2em"
        }}
    >
        Mientras tanto, puedes adquirir nuestros productos a través de Whatsapp en el número: <strong><a
                href="https://wa.me/524791416185"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    color: '#000',
                    padding: '5px',
                    zIndex: '1000',
                    textAlign: 'center',
                    cursor: 'pointer',
                    //display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >(52) 4791416185
            </a></strong>
    </Typography>
</Box>

        </Box>
    );
};

export default Checkout;
