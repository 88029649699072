import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from '@mui/material';
import { theme } from "./theme";
import { Provider } from "react-redux";
import { configureStore } from '@reduxjs/toolkit';
import cartReducer from "./state";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';

// Configuración de redux-persist
const persistConfig = {
  key: 'root', // La clave en la que se guardará el estado en el storage
  storage,     // Tipo de almacenamiento (localStorage)
};

// Crear un persistReducer combinando el reducer con persistConfig
const persistedReducer = persistReducer(persistConfig, cartReducer);

const store = configureStore({
  reducer: { cart: persistedReducer },
});

const persistor = persistStore(store); // Crear el persistor

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
